@import 'styles/variables.scss';

.container {
  margin-inline: auto;
  max-width: $screens-lg;
  overflow: hidden;
  position: relative;

  @media (min-width: $screens-sm) {
    padding-inline: var(--spacing-5);
  }

  @media (min-width: $screens-lg) {
    padding-inline: var(--spacing-4);
  }
}

.content {
  margin-bottom: var(--spacing-4);
  padding-bottom: var(--spacing-1);

  @media (min-width: $screens-lg) {
    margin-bottom: 0;
    width: 28rem;
  }
}

.contentWrapper {
  padding: var(--spacing-28) var(--spacing-5) var(--spacing-10);
  position: relative;
  text-align: center;

  @media (min-width: $screens-sm) {
    padding-inline: 0;
  }

  @media (min-width: $screens-md) {
    grid-column: span 1 / span 1;
    margin-right: var(--spacing-8);
    padding-bottom: 0;
    padding-top: var(--spacing-20);
    text-align: left;
  }

  @media (min-width: $screens-lg) {
    grid-column: span 2 / span 2;
    margin-right: 0;
  }
}

.errorCode {
  font-weight: 600;
  padding-bottom: var(--spacing-5);
}

.topBg {
  background-image: url('/v2/images/top-oval-shape.svg');
  background-position: 50% 0%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;

  @media (min-width: $screens-md) {
    right: auto;
    background-position: top center;
  }
}

.bottomBg {
  background: url('/v2/images/bottom-oval-shape.svg') 57% -12% no-repeat;
  background-size: cover;
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: -15px;

  @media (min-width: $screens-md) {
    background-position: top center;
    background-size: auto;
    bottom: -10px;
    left: 0;
    right: auto;
  }
}

.bottomBgDesktop {
  display: none;

  @media (min-width: $screens-md) {
    display: block;
  }
}

.bottomBgMobile {
  @media (min-width: $screens-md) {
    display: none;
  }
}

.getInTouch {
  @media (min-width: $screens-md) {
    grid-column: span 1 / span 1;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $screens-lg) {
    flex: none;
    flex-wrap: nowrap;
  }
}

.navItem {
  margin-bottom: var(--spacing-4);
  text-align: center;
  width: 50%;

  @media (min-width: $screens-lg) {
    margin-bottom: var(--spacing-2);
    text-align: left;
    width: 100%;
  }
}

.page {
  position: relative;
}

.text {
  font-size: var(--text-xl-size);
  padding-bottom: var(--spacing-6);
}

.text2 {
  font-weight: 600;
}

.title {
  font-size: 44px;
  padding-bottom: var(--spacing-10);

  @media (min-width: $screens-sm) {
    font-size: 52px;
  }
}

.wrapper {
  padding-top: var(--spacing-10);

  @media (min-width: $screens-md) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: var(--spacing-12);
    padding-top: var(--spacing-28);
  }

  @media (min-width: $screens-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-bottom: var(--spacing-28);
  }
}
